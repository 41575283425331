const Checkbox = {
	MuiCheckbox: {
		styleOverrides: {
			root: {
				borderRadius: '0',
				'& .MuiSvgIcon-root': {
					fontSize: 'calc(var(--spacing-1) * 1.75)',
					height: 'calc(var(--spacing-1) * 2.25)',
					width: 'calc(var(--spacing-1) * 2.25)',
				},
				// 	fontSize: globalTheme.typography.fontSize,
				// 	padding: 0,
				// 	marginRight: globalTheme.spacing(2),
				// 	width: globalTheme.spacing(2.5),
				// 	height: globalTheme.spacing(2.5),
				// 	// colorDefault
				// 	color: globalTheme.palette.grey.light,
				// 	'&:hover': {
				// 		color: globalTheme.palette.grey.medium,
				// 	},
				// 	'&:focus': {
				// 		color: globalTheme.palette.grey.main,
				// 	},
				// 	'&.Mui-checked': {
				// 		color: globalTheme.palette.primary.main,
				// 		'&:hover': {
				// 			color: globalTheme.palette.grey.medium,
				// 		},
				// 		'&:focus': {
				// 			color: globalTheme.palette.grey.main,
				// 		},
				// 	},
				// 	'&.Mui-disabled': {
				// 		color: globalTheme.palette.grey.light,
				// 	},
			},
			colorPrimary: {
				color: 'var(--color-primary)',
				// '&:hover': {
				// 	color: globalTheme.palette.grey.medium,
				// },
				// '&:focus': {
				// 	color: globalTheme.palette.grey.main,
				// },
				// '&.Mui-checked': {
				// 	color: globalTheme.palette.primary.main,
				// 	'&:hover': {
				// 		color: globalTheme.palette.grey.medium,
				// 	},
				// 	'&:focus': {
				// 		color: globalTheme.palette.grey.main,
				// 	},
				// },
				// '&.Mui-disabled': {
				// 	color: globalTheme.palette.grey.light,
				// },
			},
			// colorSecondary: {
			// 	color: globalTheme.palette.grey.light,
			// 	'&:hover': {
			// 		color: globalTheme.palette.grey.medium,
			// 	},
			// 	'&:focus': {
			// 		color: globalTheme.palette.grey.main,
			// 	},
			// 	'&.Mui-checked': {
			// 		color: globalTheme.palette.primary.contrastText,
			// 		'&:hover': {
			// 			color: globalTheme.palette.grey.light,
			// 		},
			// 		'&:focus': {
			// 			color: globalTheme.palette.grey.main,
			// 		},
			// 	},
			// 	'&.Mui-disabled': {
			// 		color: globalTheme.palette.grey.light,
			// 	},
			// },
			// colorError: {
			// 	...colorPrimary,
			// },
			// colorInfo: {
			// 	...colorPrimary,
			// },
			// colorSuccess: {
			// 	...colorPrimary,
			// },
			// colorWarning: {
			// 	...colorPrimary,
			// },
		},
	},
}

export default Checkbox
