export const fontSize = 14
export const htmlFontSize = 14
export const fonts = {
	aGaramondPro: {
		fontFamily: '"adobe-garamond-pro"',
		fontWeight: 400,
	},
	helveticaNeue: {
		fontFamily: '"Helvetica Neue"',
		fontWeight: 400,
	},
	helveticaNeueLight: {
		fontFamily: '"Helvetica Neue Light"',
		fontWeight: 200,
	},
}
