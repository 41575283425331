const Form = {
	MuiFormLabel: {
		styleOverrides: {
			asterisk: {
				marginLeft: '0px',
			},
		},
	},
	MuiFormControlLabel: {
		defaultProps: {
			labelPlacement: 'start',
		},
		styleOverrides: {
			root: {
				flexDirection: 'row',
			},
			label: {
				color: 'var(--color-grey120)',
				fontSize: '10px',
			},
		},
	},
}

export default Form
