const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				borderRadius: 0,
				'&.SizeGuide-root': {
					'& .MuiPaper-root': {
						minHeight: 'initial',
					},
				},
			},
			paper: {
				borderRadius: 0,
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				borderRadius: 0,
				scrollbarWidth: 'thin',
				scrollbarColor: 'var(--color-black) var(--color-white)',
				'&.MuiDialog-paper': {
					scrollbarWidth: 'thin',
					scrollbarColor: 'var(--color-black) var(--color-white)',
				},
			},
		},
	},
}

export default Dialog
