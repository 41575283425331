import theme from '../theme'
import BluHeadlessApp from '@bluheadless/ui-logic/src/app'
import { useEventQueue } from '@bluheadless/ui-logic/src/core-web-vitals/event-queue'
import createEmotionCache from '../create-emotion-cache' // Can't be moved to @bluheadless
import { GlobalStyles } from '@mui/material'
import { css } from '@mui/system'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const ProjectApp = ({ emotionCache = clientSideEmotionCache, ...props }) => {
	return (
		<>
			<GlobalStyles
				styles={css`
					@font-face {
						font-family: 'adobe-garamond-pro';
						src: url('https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
								format('woff2'),
							url('https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
								format('woff'),
							url('https://use.typekit.net/af/5cace6/00000000000000003b9b00c2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
								format('opentype');
						font-display: auto;
						font-style: italic;
						font-weight: 400;
						font-stretch: normal;
					}
					@font-face {
						font-family: 'Helvetica Neue Light';
						src: url('/fonts/helveticaneue-light.woff2') format('woff2');
						font-weight: 200;
						font-style: normal;
						ascent-override: 80%;
					}
					@font-face {
						font-family: 'Helvetica Neue';
						src: url('/fonts/helveticaneue.woff2') format('woff2');
						font-weight: 400;
						font-style: normal;
						ascent-override: 80%;
					}
				`}
			/>
			<BluHeadlessApp emotionCache={emotionCache} theme={theme} {...props} />
		</>
	)
}

// Here we can customize global server-side props
ProjectApp.getInitialProps = async (appContext) => {
	const initialProps = await BluHeadlessApp.getInitialProps(appContext)

	return {
		...initialProps,
		pageProps: {
			...initialProps.pageProps,
		},
	}
}

export function reportWebVitals(metric) {
	useEventQueue.getState().add(metric)
}

export default ProjectApp
